@import url('https://fonts.googleapis.com/css2?family=Inter&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
.btn-wa {
  width: 60px;
}
.act-btn{
  /* background:green; */
  display: block;
  width: 30px;
  height: 30px;
  line-height: 50px;
  text-align: center;
  color: white;
  font-size: 30px;
  font-weight: bold;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  text-decoration: none;
  transition: ease all 0.3s;
  position: fixed;
  right: 45px;
  bottom: 45px;
  z-index: 999;
}



.judul {
    font-size: 50px;
    font-family: Poppins;
    font-weight: 700;
    line-height: 62px;
}
.title {
    font-size: 18px;
    font-weight: 400;
    font-family: Poppins;
    line-height: 30px;
    color: #6D6D6D;
}
.title-2 {
    font-size: 36px;
    font-family: Poppins;
    font-weight: 700;
    line-height: 65px;
}
.slick-slide {
    margin: 0px 20px;
}
.title-accordion {
    font-family: Poppins;
    font-weight: 500;
    font-size: 24px;
    padding: 10px 30px 10px 30px;
    line-height: 28px;
    color: #fff;
}
.text-accordion {
    font-family: Inter;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    padding: 20px 50px 25px 50px;
}
.collapsed {
  background-color: #fff !important;
  color: black !important;
}

.card-info{
    font-family: Poppins;
    font-weight: 600;
    font-size: 24px;
    line-height: 52px;
    padding: 100px;
    text-align: center;
    color: #fff;
}

.title-layanan {
    font-family: Poppins;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
}

.subtitle-layanan {
    font-family: Poppins;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    color: #9E9E9E;
}

.title-land {
    font-family: Poppins;
    font-weight: 600;
    font-size: 20px;
    line-height: 34px;
    color: black;
}

.subtitle-land {
    font-family: Poppins;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #6D6D6D;
}

.title-promo {
    font-family: Poppins;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
}

.harga-asli {
    font-family: Poppins;
    font-weight: 600;
    font-size: 28px;
    color: #FF1F00;
}

.harga-promo {
    font-family: Poppins;
    font-weight: 600;
    font-size: 52px;
}

li {
    display: inline-block;
    font-size: 1.5em;
    list-style-type: none;
    padding: 1em;
    text-transform: uppercase;
    font-family: Poppins;
    font-weight: 600;
    color: #F54A4A;
  }
  
  li span {
    display: block;
    font-size: 3.5rem;
    font-family: Poppins;
    font-weight: 600;
    color: #F54A4A;
  }

  .title-service {
    font-family: Poppins;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

  .text {
    font-family: Poppins;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
  }

  .k1 {
    font-family: Poppins;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  }

  .n1 {
    font-family: Poppins;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 0.25px;
    color: #B6B6B6;
  }

  .slick-list {
    /* background-color: #FF1F00; */
    max-height: 450px;
  }

  /* .slick-track {
    background-color: blue;
    max-height: 450px;
  } */
  @media all and (max-width: 1440px) {
    .slick-list {
      /* background-color: #00b42d; */
      max-height: 360px;
    }
  }


  @media all and (max-width: 768px) {
    
   li {
        /* font-size: calc(1.125rem * var(--smaller)); */
        font-family: Poppins;
        font-weight: 500;
        font-size: 16px;
        color: #F54A4A;
      }
      
      li span {
        /* font-size: calc(3.375rem * var(--smaller)); */
        font-size: 30px;
        font-family: Poppins;
        font-weight: 600;
        color: #F54A4A;
      }
  }

  @media all and (max-width: 768px) {
    .btn-wa {
      width: 60px;
    }
    .act-btn{
      /* background:green; */
      display: block;
      width: 30px;
      height: 30px;
      line-height: 50px;
      text-align: center;
      color: white;
      font-size: 30px;
      font-weight: bold;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      text-decoration: none;
      transition: ease all 0.3s;
      position: fixed;
      right: 45px;
      bottom: 45px;
      z-index: 999;
    }
    .judul {
        font-size: 23px;
        font-family: Poppins;
        font-weight: 700;
        line-height: 26px;
    }
    .title {
        font-size: 14px;
        font-weight: 400;
        font-family: Poppins;
        line-height: 18px;
        color: #6D6D6D;
    }
    .title-2 {
        font-size: 23px;
        font-family: Poppins;
        font-weight: 700;
        line-height: 28px;
    }
    .slick-slide {
        margin: 0px 20px;
    }
    .title-accordion {
        font-family: Poppins;
        font-weight: 500;
        font-size: 12px;
        padding: 10px 30px 10px 30px;
        line-height: 20px;
        color: #fff;
    }
    .text-accordion {
        font-family: Inter;
        font-weight: 400;
        font-size: 10px;
        line-height: 20px;
        padding: 30px 50px 65px 50px;
        color: #fff;
        text-align: justify;
    }
    .collapsed {
      background-color: #fff !important;
      color: black !important;
    }
    
    .card-info{
        font-family: Poppins;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        padding: 30px;
        text-align: center;
    }
    
    .title-layanan {
        font-family: Poppins;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
    }
    
    .subtitle-layanan {
        font-family: Poppins;
        font-weight: 400;
        font-size: 13px;
        line-height: 25px;
        color: #9E9E9E;
    }
    
    .title-land {
        font-family: Poppins;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        color: black;
    }
    
    .subtitle-land {
        font-family: Poppins;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #6D6D6D;
    }
    
    .title-promo {
        font-family: Poppins;
        font-weight: 500;
        font-size: 17px;
        line-height: 24px;
    }
    
    .harga-asli {
        font-family: Poppins;
        font-weight: 600;
        font-size: 20px;
        color: #FF1F00;
    }
    
    .harga-promo {
        font-family: Poppins;
        font-weight: 600;
        font-size: 30px;
    }
    
    /* li {
        display: inline-block;
        font-size: 1.5em;
        list-style-type: none;
        padding: 1em;
        text-transform: uppercase;
        font-family: Poppins;
        font-weight: 600;
      }
      
      li span {
        display: block;
        font-size: 4.5rem;
        font-family: Poppins;
        font-weight: 600;
      } */
    
      .title-service {
        font-family: Poppins;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
      }
    
      .text {
        font-family: Poppins;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
      }

      .k1 {
        font-family: Poppins;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        /* text-align: justify; */
      }
    
      .n1 {
        font-family: Poppins;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        color: #B6B6B6;
      }
  }